import { useCallback, useMemo } from 'react';
import { AnyValue } from 'types/utils';

import { FieldValidator } from 'components/form/form.types';
import { useFormatMessage } from 'utilities/i18n';

export function maxLengthValidator(maxLength: number) {
  return (value: AnyValue) => typeof value === 'string' && value.length > maxLength;
}

export function useMaxLengthValidator(maxLength: number): FieldValidator {
  const t = useFormatMessage();
  const validator = useMemo(() => maxLengthValidator(maxLength), [maxLength]);

  return useCallback(
    value => {
      if (validator(value)) {
        return {
          descriptor: { id: t('spark_page.create.input.max_length', { values: { count: maxLength } }) },
          parameters: { length: maxLength },
        };
      }

      return undefined;
    },
    [maxLength, t, validator],
  );
}
