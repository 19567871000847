import { useAtom } from 'jotai';
import { useHydrateAtoms } from 'jotai/utils';
import { useRouter } from 'next/router';

import { SHAPE_QUERY_KEY } from 'components/pages/sparkPage/context/CreateSparkPrompt';
import { atomWithLocalStorage } from 'lib/jotai-utils';
import { unArray } from 'utilities/js';

const shapeAtom = atomWithLocalStorage<string | null>('spark_shape', null);

export function useHydrateShape() {
  const initialValue = useInitialShapeValue();

  useHydrateAtoms(new Map([[shapeAtom, initialValue]]));
}

export function useShape() {
  return useAtom(shapeAtom);
}

function useInitialShapeValue() {
  const router = useRouter();
  const queryValue = unArray(router.query[SHAPE_QUERY_KEY]);

  return queryValue;
}
