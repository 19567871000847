import { atom } from 'jotai';

export function atomWithLocalStorage<T>(key: string, initialValue: T | null) {
  const getInitialValue = () => {
    if (typeof window === 'undefined') {
      return initialValue;
    }

    const item = localStorage.getItem(key);

    if (item) {
      try {
        return JSON.parse(item);
      } catch {
        localStorage.removeItem(key);
      }
    }

    return initialValue;
  };

  const baseAtom = atom(getInitialValue());

  const derivedAtom = atom(
    get => get(baseAtom),
    (get, set, update) => {
      const nextValue = typeof update === 'function' ? update(get(baseAtom)) : update;

      set(baseAtom, nextValue);

      if (typeof window !== 'undefined') {
        localStorage.setItem(key, JSON.stringify(nextValue));
      }
    },
  );

  return derivedAtom;
}
