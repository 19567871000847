import { createContext, useCallback, useMemo, useState } from 'react';

import { ImageRatios } from 'components/pages/sparkPage/components/sparkImageRatioSelect/SparkImageRatioSelect.types';
import { maxInputLength } from 'components/pages/sparkPage/config/sparkConfig';
import { useSparkPageContext } from 'components/pages/sparkPage/context/SparkPageContext';
import { useImageUpload } from 'components/pages/sparkPage/hooks/useImageUpload';
import { useLocalPrompts } from 'components/pages/sparkPage/hooks/useLocalPrompts';
import {
  useCurrentPage,
  useInput,
  useMonthFilter,
  usePromptErrorMessage,
  usePromptFilter,
  usePromptFilters,
  useYearFilter,
} from 'components/pages/sparkPage/state';
import {
  getImageAspectRatioFromImageRatios,
  getInitialImageRatioMap,
} from 'components/pages/sparkPage/utils/getImageAspectRatio';
import { maxLengthValidator } from 'components/pages/sparkPage/utils/maxLengthValidator';
import {
  calculateHasPromptImageInLoadingState,
  sparkToolToCreationType,
} from 'components/pages/sparkPage/utils/prompts';
import { toPrompt } from 'components/pages/sparkPage/utils/utils';
import {
  useCreateSignedUploadUrlMutation,
  useProductListQuery,
  useSparkPromptsQuery,
  useSparkQueueQuery,
} from 'generated/graphql';
import { useAuthState } from 'hooks/useAuthState/useAuthState';
import { useLocalStorage } from 'hooks/useLocalStorage/useLocalStorage';
import { Sentry } from 'utilities/sentry';

import { useSparkPageCreateSetInput, useSparkUserCredits } from './CreateSparkPrompt.hooks';
import {
  CreateSparkPromptContextValue,
  CreateSparkPromptImageRatioGetterContextValue,
  CreateSparkPromptImageRatioSetterContextValue,
  CreateSparkPromptInputGetterContextValue,
  CreateSparkPromptInputSetterContextValue,
  CreateSparkPromptProviderProps,
  OnClickTryPrompt,
  OnSendPrompt,
  SparkPageProductListContextValue,
  SparkPageSubmitContextValue,
  SparkPageUploadContextValue,
  UploadedImageSource,
} from './CreateSparkPrompt.types';
import {
  useCreateSparkPromptMutationContext,
  useCreateSparkPromptMutationDataContext,
} from '~/sparkPage/context/CreateSparkPromptMutation';
import { useSyncCurrentPageToQueryParams } from '~/sparkPage/hooks/useSyncCurrentPageToQueryParams';

export const PROMPT_TEXT_QUERY_KEY = 'pt';
export const IMAGE_QUERY_KEY = 'img';
export const MODEL_QUERY_KEY = 'model';
export const TYPE_QUERY_KEY = 'type';
export const SHAPE_QUERY_KEY = 'shape';

export const CreateSparkPromptContext = createContext<CreateSparkPromptContextValue>(null!);

export const SparkPageInputSetterContext = createContext<CreateSparkPromptInputSetterContextValue>(null!);
export const SparkPageInputGetterContext = createContext<CreateSparkPromptInputGetterContextValue>(null!);
export const SparkPageImageRatioSetterContext = createContext<CreateSparkPromptImageRatioSetterContextValue>(null!);
export const SparkPageImageRatioGetterContext = createContext<CreateSparkPromptImageRatioGetterContextValue>(null!);

export const SparkPageProductListContext = createContext<SparkPageProductListContextValue>(null!);
export const SparkPageUploadContext = createContext<SparkPageUploadContextValue>(null!);
export const SparkPageSubmitContext = createContext<SparkPageSubmitContextValue>(null!);

export * from './CreateSparkPrompt.hooks';

/**
 * This Context is mainly used for shared functions and helpers.
 *
 *
 * It currently does include some state for performance reasons, like how we split up
 * the spark input prompt into a getter and setter. This is so a component can set the
 * state of an input, and not need to also listen to the prompt changing. This is currently
 * done with two contextes.
 *
 * It also does include other state, although this should be moved into "~/sparkPage/state".
 */
export function CreateSparkPromptProvider({ children }: CreateSparkPromptProviderProps) {
  return (
    <CreateSparkPromptInputProvider>
      <CreateSparkPromptProviderRoot>
        <CreateSparkPromptImageRatioProvider>{useMemo(() => children, [children])}</CreateSparkPromptImageRatioProvider>
      </CreateSparkPromptProviderRoot>
    </CreateSparkPromptInputProvider>
  );
}

function CreateSparkPromptInputProvider({ children }: { children: React.ReactNode }) {
  const [input, setInput] = useInput();

  return (
    <SparkPageInputSetterContext.Provider value={setInput}>
      <SparkPageInputGetterContext.Provider value={input}>
        {useMemo(() => children, [children])}
      </SparkPageInputGetterContext.Provider>
    </SparkPageInputSetterContext.Provider>
  );
}

function CreateSparkPromptImageRatioProvider({ children }: { children: React.ReactNode }) {
  const { tool } = useSparkPageContext();
  const [imageRatio, setImageRatio] = useState<ImageRatios>(getInitialImageRatioMap(tool));

  return (
    <SparkPageImageRatioSetterContext.Provider value={setImageRatio}>
      <SparkPageImageRatioGetterContext.Provider value={imageRatio}>
        {useMemo(() => children, [children])}
      </SparkPageImageRatioGetterContext.Provider>
    </SparkPageImageRatioSetterContext.Provider>
  );
}

function CreateSparkPromptProviderRoot({ children }: CreateSparkPromptProviderProps) {
  const { user } = useAuthState();
  const { tool, sparkInputRef } = useSparkPageContext();
  const [, setErrorMessage] = usePromptErrorMessage();
  const { localPrompts } = useLocalPrompts();
  const setInput = useSparkPageCreateSetInput();
  const upload = useImageUpload();
  const createSignedUploadUrl = useCreateSignedUploadUrlMutation();
  const [uploadedImageSource, setUploadedImageSource] = useState<UploadedImageSource>(null);
  const [uploadedImageUrl, setUploadedImageUrl] = useState<string>('');
  const [monthFilter, setMonthFilter] = useMonthFilter();
  const [yearFilter, setYearFilter] = useYearFilter();
  const [promptFilter, setPromptFilter] = usePromptFilter();
  const [filter] = usePromptFilters();
  const [currentPage, setCurrentPage] = useCurrentPage();
  const { isLoading } = useCreateSparkPromptMutationDataContext();
  const mutation = useCreateSparkPromptMutationContext();

  // there are some requirements in order to be able to display the upscale overlay
  // so we can check for these requirements, and then set the value of this boolean
  const [hasUpscaleOverlayRequirementsMet, setHasUpscaleOverlayRequirementsMet] = useState(false);

  // this value is the value that is kept in storage, to know if the user has already seen the overlay.
  // so if they have, we should not show it even if the above requirements are met.
  const [hasSeenUpscaleOverlayValue, setHasSeenUpscaleOverlay] = useLocalStorage([
    'hasSeenUpscaleOverlay',
    user?.identification.id,
  ]);

  // so `useLocalStorage` will save the initial value into storage, it takes precedence over the default value.
  // so we cannot send in a default value into useLocalStorage if we want it to be saved before page sessions.
  // the solution is to not send in a default value, and the `useLocalStorage` hook will return null if there is no value in storage.
  const hasSeenUpscaleOverlay = Boolean(hasSeenUpscaleOverlayValue);

  useSyncCurrentPageToQueryParams(currentPage);

  const creationType = sparkToolToCreationType(tool);

  const prompts = useSparkPromptsQuery(
    { page: currentPage, creationType, filter },
    {
      enabled: Boolean(user?.identification.id),
      keepPreviousData: true, // this will display the previous pages data until the new one is received
    },
  );

  const productList = useProductListQuery(
    {
      categoryId: Number(process.env.NEXT_PUBLIC_COMMUNITY_CONTENT_CATEGORY_ID),
      limit: 99,
      offset: 0,
    },
    {
      enabled: Boolean(
        prompts.data &&
          prompts.data?.me?.user?.spark?.prompts?.items?.length === 0 &&
          prompts.isSuccess &&
          !monthFilter &&
          !yearFilter &&
          !promptFilter,
      ),
    },
  );

  const queue = useSparkQueueQuery(
    {},
    {
      enabled: Boolean(user?.identification.id) && prompts?.data && prompts?.data.me?.user?.spark.balance === 0,
    },
  );

  const userCredits = useSparkUserCredits();

  const hideUpscaleOverlay = useCallback(
    function hideUpscaleOverlay() {
      setHasSeenUpscaleOverlay(true);
      setHasUpscaleOverlayRequirementsMet(false);
    },
    [setHasSeenUpscaleOverlay, setHasUpscaleOverlayRequirementsMet],
  );

  const userSparkFastCreditBalance = userCredits.data?.getSparkUserCredits.balance || 0;
  const isUpscaleOverlayVisible = !hasSeenUpscaleOverlay && hasUpscaleOverlayRequirementsMet;

  const focusSparkInput = useCallback(
    function focusSparkInput() {
      sparkInputRef.current?.getElementsByTagName('textarea')?.[0]?.focus();
    },
    [sparkInputRef],
  );

  const scrollInputIntoView = useCallback(
    function scrollInputIntoView() {
      if (sparkInputRef.current?.scrollIntoView) {
        // do not scroll the user to the input if they are still at the
        // top of the page
        if (window.scrollY > 0) {
          sparkInputRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      } else {
        window.scrollTo(0, 0);
      }
    },
    [sparkInputRef],
  );

  const promptsData = prompts.data?.me?.user?.spark.prompts;

  // we also decide if we have images on the page that are in a loading state
  // so we known wether or not we need to act upon them, like refetching that page
  const { prompts: promptsList, pageHasPromptImageInLoadingState } = useMemo(() => {
    const items = promptsData?.items ?? [];

    const hasPromptImageInLoadingState = calculateHasPromptImageInLoadingState(items);

    if (!items || !Array.isArray(items)) {
      return { prompts: [], pageHasPromptImageInLoadingState: hasPromptImageInLoadingState };
    }

    return {
      prompts: items.map(toPrompt),
      pageHasPromptImageInLoadingState: hasPromptImageInLoadingState,
    };
  }, [promptsData?.items]);

  const queueData = queue.data?.me?.user?.spark.queue;
  const isInQueue = queueData?.position !== null && queueData?.position !== undefined && queueData?.position > 0;
  const queuingPrompt = isInQueue ? localPrompts[0] || (queueData?.prompt && toPrompt(queueData.prompt)) : null;

  const handleUpload = useCallback(
    async (file: File) => {
      createSignedUploadUrl.mutate(
        { fileName: file.name },
        {
          onSuccess: signedUrlData => {
            if (
              !signedUrlData.createSignedUploadUrl ||
              signedUrlData.createSignedUploadUrl.errors ||
              !signedUrlData.createSignedUploadUrl.url
            ) {
              const err = new Error('No signed URL returned', {
                cause: signedUrlData,
              });
              Sentry.captureException(new Error(err.message));
              throw err;
            }

            upload.mutate(
              {
                image: file,
                signedUrl: signedUrlData.createSignedUploadUrl.url,
              },
              {
                onSuccess: data => {
                  if (data.url) {
                    setInput(data.url);
                    setUploadedImageSource('image');
                    setUploadedImageUrl(data.url);
                  } else {
                    const err = new Error('Error returned from image upload', {
                      cause: data,
                    });
                    Sentry.captureException(new Error(err.message));
                    throw err;
                  }
                },
              },
            );
          },
        },
      );
    },
    [createSignedUploadUrl, upload, setInput],
  );

  const handleUploadReset = useCallback(() => {
    upload.reset();
    setUploadedImageSource(null);
    setUploadedImageUrl('');
  }, [upload]);

  const onClickTryPrompt: OnClickTryPrompt = useCallback(
    (prompt, options = {}) => {
      if (tool === 'cf-spark-imagemix') {
        setUploadedImageSource(options.uploadedImageSource || null);
        setUploadedImageUrl(options.uploadedImageSource === 'image' ? prompt : '');
      }

      setUploadedImageSource('use-again');
      setInput(prompt);

      if (options.scrollInputIntoView) {
        scrollInputIntoView();
      }
    },
    [tool, setInput, scrollInputIntoView],
  );

  const onSendPrompt = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    async (args: OnSendPrompt) => {
      setErrorMessage(null);

      if (args.type === 'spark' && mutation.type === 'spark') {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const { prompt, ratio, creationType } = args;

        await mutation.result.mutateAsync({
          prompt,
          ratio: getImageAspectRatioFromImageRatios(ratio),
          creationType,
        });
      }

      if (args.type === 'crystalline' && mutation.type === 'crystalline') {
        const { prompt, modelTypeVersion, ratio } = args;

        await mutation.result.mutateAsync({
          prompt,
          ratio: getImageAspectRatioFromImageRatios(ratio),
          modelTypeVersion,
        });
      }
    },
    [mutation.result, mutation.type, setErrorMessage],
  );

  const showProductList =
    tool === 'cf-spark-art' &&
    promptsList.length === 0 &&
    productList.data?.getProductList?.data?.length !== 0 &&
    !monthFilter &&
    !yearFilter &&
    !promptFilter;

  const isShowingUsersSparkPrompts = !showProductList;

  const preventPromptGeneration = (isLoading && userSparkFastCreditBalance === 0) || isInQueue;

  const emptyFilteredPrompts =
    promptsList.length === 0 &&
    productList.data?.getProductList?.data?.length !== 0 &&
    Boolean(monthFilter || yearFilter || promptFilter);

  const clearPromptFilters = useCallback(() => {
    setMonthFilter(null);
    setYearFilter(null);
    setPromptFilter(null);
    setCurrentPage(1);
  }, [setMonthFilter, setCurrentPage, setPromptFilter, setYearFilter]);

  const validationPerTool = useCallback(
    (input: string) => {
      const maxLengthValidatorPatterns = maxLengthValidator(maxInputLength['cf-spark-patterns']);

      if (tool === 'cf-spark-patterns') {
        const maxLengthCheck = maxLengthValidatorPatterns(input);

        if (maxLengthCheck) {
          setErrorMessage([
            'spark_page.create.input.max_length',
            { values: { count: maxInputLength['cf-spark-patterns'] } },
          ]);

          return false;
        }
      }

      return true;
    },
    [setErrorMessage, tool],
  );

  const onValidateInput = useCallback(
    (input: string) => {
      setErrorMessage(null);

      if (preventPromptGeneration) {
        return false;
      }

      if (!validationPerTool(input)) {
        return false;
      }

      if (!input) {
        setErrorMessage(['spark_page.create.prompt.error.no_prompt']);

        return false;
      }

      return true;
    },
    [setErrorMessage, preventPromptGeneration, validationPerTool],
  );

  return (
    <SparkPageSubmitContext.Provider
      value={{
        onSendPrompt,
      }}
    >
      <SparkPageProductListContext.Provider
        value={useMemo(
          () => ({
            data: productList.data,
            refetch: productList.refetch,
          }),
          [productList.data, productList.refetch],
        )}
      >
        <SparkPageUploadContext.Provider
          value={useMemo(
            () => ({
              data: { url: uploadedImageUrl },
              reset: handleUploadReset,
              status: upload.status,
              variables: upload.variables,
              isLoading: upload.isLoading,
              error: upload.error,
              handleUpload,
            }),
            [
              handleUpload,
              handleUploadReset,
              upload.error,
              upload.isLoading,
              upload.status,
              upload.variables,
              uploadedImageUrl,
            ],
          )}
        >
          <CreateSparkPromptContext.Provider
            value={useMemo(
              () => ({
                hasUpscaleOverlayRequirementsMet,
                setHasUpscaleOverlayRequirementsMet,
                hasSeenUpscaleOverlay: Boolean(hasSeenUpscaleOverlay),
                hideUpscaleOverlay,
                isUpscaleOverlayVisible,
                focusSparkInput,
                scrollInputIntoView,
                currentPage,
                isInQueue,
                onClickTryPrompt,
                pageHasPromptImageInLoadingState,
                isShowingUsersSparkPrompts,
                showProductList,
                emptyFilteredPrompts,
                preventPromptGeneration,
                userSparkFastCreditBalance,
                queuingPrompt,
                uploadedImageSource,
                clearPromptFilters,
                onValidateInput,
              }),
              [
                hasUpscaleOverlayRequirementsMet,
                hasSeenUpscaleOverlay,
                hideUpscaleOverlay,
                isUpscaleOverlayVisible,
                focusSparkInput,
                scrollInputIntoView,
                currentPage,
                isInQueue,
                onClickTryPrompt,
                pageHasPromptImageInLoadingState,
                isShowingUsersSparkPrompts,
                showProductList,
                emptyFilteredPrompts,
                preventPromptGeneration,
                userSparkFastCreditBalance,
                queuingPrompt,
                uploadedImageSource,
                clearPromptFilters,
                onValidateInput,
              ],
            )}
          >
            {useMemo(() => children, [children])}
          </CreateSparkPromptContext.Provider>
        </SparkPageUploadContext.Provider>
      </SparkPageProductListContext.Provider>
    </SparkPageSubmitContext.Provider>
  );
}
