import { Tooltip as MuiTooltip, TooltipProps } from '@mui/material';
import { styled } from '@mui/material';

export const Tooltip = styled(({ className, classes, ...props }: TooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className, ...classes }} />
))(({ theme }) => ({
  '& .MuiTooltip-tooltip': {
    fontSize: 11,
    maxWidth: theme.spacing(32),
    borderRadius: theme.spacing(0.25),
    fontWeight: theme.typography.fontWeightRegular,
    '&.primary': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.white.main,
      padding: theme.spacing(1.25, 2),
    },
    '&.secondary': {
      backgroundColor: theme.palette.white.main,
      boxShadow: theme.shadows[2],
      color: theme.palette.black.main,
      padding: theme.spacing(1.25),
    },
    '&.tertiary': {
      backgroundColor: '#403068',
      color: theme.palette.white.main,
      padding: theme.spacing(1.25, 2),
    },
    '&.tertiaryLight': {
      backgroundColor: theme.palette.primary.extraLight,
      color: theme.palette.primary.dark,
      padding: theme.spacing(1.25, 2),
    },
    '&.cfPremium': {
      backgroundColor: '#ffab2a',
      color: theme.palette.white.main,
      padding: theme.spacing(1.25, 2),
    },
  },
  '& .MuiTooltip-arrow': {
    fontSize: 18,
    '&.primary::before': {
      backgroundColor: theme.palette.secondary.main,
    },
    '&.secondary::before': {
      backgroundColor: theme.palette.common.white,
      boxShadow: '0 0 3px rgb(0 0 0 / 2%)',
    },
    '&.tertiary::before': {
      backgroundColor: '#403068',
    },
    '&.tertiaryLight::before': {
      backgroundColor: theme.palette.primary.extraLight,
    },
    '&.cfPremium::before': {
      backgroundColor: '#ffab2a',
    },
  },
}));
