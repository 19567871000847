import { callAll } from 'cf-ui';
import { unstable_batchedUpdates } from 'react-dom';
import { MutationOptions, useQueryClient } from '@tanstack/react-query';
import { AnyObject, Truthy } from 'types/utils';

import { Image, ImageVariant } from 'components/pages/sparkPage/components/sparkPageCreate/SparkPageCreate.types';
import { getAmountOfImagesGeneratedByTool } from 'components/pages/sparkPage/config/sparkConfig';
import {
  useSparkPrompts,
  useSparkQueue,
  useSparkUserCreditsBalance,
} from 'components/pages/sparkPage/context/CreateSparkPrompt';
import { LocalPrompt } from 'components/pages/sparkPage/context/CreateSparkPrompt.types';
import { useSparkPageContext } from 'components/pages/sparkPage/context/SparkPageContext';
import { useCurrentPage, usePromptErrorMessage } from 'components/pages/sparkPage/state';
import { getAspectRatioForPrompt, toPrompt } from 'components/pages/sparkPage/utils/utils';
import { CreationType, ImageRatio, ModelTypeVersion, useSparkUserCreditsQuery } from 'generated/graphql';
import { useFormatMessage } from 'utilities/i18n';

import { useLocalPrompts } from './useLocalPrompts';
import { useUpdateSparkCredits } from './useUpdateSparkCredits';

type Variables = Partial<{
  prompt: string;
  ratio: ImageRatio;
  creationType: CreationType;
  modelTypeVersion: ModelTypeVersion;
}>;

type UseCreateSparkPromptOptionsArgs<D, V, C> = Omit<MutationOptions<D, unknown, V, C>, 'onMutate'> & {
  resolveErrorMessage(data: D): string | void;
  onMutate?(): void;
};

type Context = {
  id: string;
};

/**
 * This hook is used to create the options to send to useMutation, it controls
 * the optimistic UI updates, instant updates like updating the user's spark
 * credits, and the refetching of the prompts and queue.
 * @returns Options to send to useMutation
 */
export function useCreateSparkPromptOptions<D, V extends Variables = Variables, C extends Context = Context>({
  resolveErrorMessage,
  onError,
  onSuccess,
  onMutate,
  ...rest
}: UseCreateSparkPromptOptionsArgs<D, V, C>) {
  const queryClient = useQueryClient();
  const prompts = useSparkPrompts({ notifyOnChangeProps: ['refetch'] });
  const queue = useSparkQueue();
  const sparkUserCreditsBalance = useSparkUserCreditsBalance();
  const [, setErrorMessage] = usePromptErrorMessage();
  const [, setCurrentPage] = useCurrentPage();
  const { tool } = useSparkPageContext();
  const { addItem, removeItem } = useLocalPrompts();
  const t = useFormatMessage();

  const updateUsersSparkCredits = useUpdateSparkCredits();
  const promptWillBeInQueue = sparkUserCreditsBalance === 0;

  return {
    ...rest,
    onMutate: async (data: V) => {
      // decrease the user's spark credits locally
      // we don't need to refetch the user's spark credits here because
      // we can let this happen when the user refocuses the window
      // or the cache will timeout
      updateUsersSparkCredits(amount => Math.max(amount - 1, 0));

      const ratio = data.ratio;
      const prompt = data.prompt as Truthy<typeof data.prompt>;
      const creationType = data.creationType as Truthy<typeof data.creationType>;

      const id = `__localPrompt__${Math.random()}_${new Date().getTime()}`;
      const coverImage = creationType === 'imagemix' ? { url: prompt } : undefined;

      const aspectRatio = await getAspectRatioForPrompt({
        prompt,
        tool,
        type: ratio,
      });

      const nextLocalPrompt: LocalPrompt = {
        variant: promptWillBeInQueue ? 'queuing' : 'generating',
        coverImage,
        ...toPrompt({
          aspectRatio,
          id,
          createTime: new Date().toISOString(),
          creationType,
          modelTypeVersion: data.modelTypeVersion,
          prompt,
          images: Array(getAmountOfImagesGeneratedByTool(tool))
            .fill(true)
            .map((_, index) => {
              return {
                id: `__loadingImage${index}__${id}`,
                __typename: ImageVariant.GENERATING,
              } as Image;
            }),
        }),
      };

      addItem(nextLocalPrompt);

      requestAnimationFrame(() => {
        unstable_batchedUpdates(() => {
          setCurrentPage(1);
          setErrorMessage(null);
        });
      });

      onMutate?.();

      return { id };
    },
    onSuccess: callAll(onSuccess, async (data: D, _variables: AnyObject, context: Context | undefined) => {
      // this should be in `onMutate` with local state otherwise there is a flash.
      // you can use `useUpdateSparkQueuePosition`
      // and do this in the `onSuccess` callback
      if (promptWillBeInQueue) {
        await queue.refetch().catch(() => {});
      }

      // refetching first page
      await prompts.refetch().catch(() => {});

      // after it's refetched we can remove the local prompt
      if (context?.id) {
        removeItem(context.id);
      }

      const errorMessage = resolveErrorMessage(data);

      if (errorMessage) {
        throw new Error(t('spark_page.generate.error.default'), {
          cause: errorMessage,
        });
      }
    }),
    onError: callAll(onError, (err: unknown, _variables: AnyObject, context?: Context) => {
      let errorMsg = t('spark_page.generate.error.default');

      // if there was an error it can mean that the user ran out of credits,
      // or the prompt failed to generate and the user was refunded.
      // in both cases, it makes sense to refetch the user's spark credits from here
      updateUsersSparkCredits(amount => amount + 1);
      queryClient.invalidateQueries(useSparkUserCreditsQuery.key({}));

      if (context?.id) {
        removeItem(context.id);
      }

      if (err instanceof Error) {
        errorMsg = err.message;
      } else if (Array.isArray(err) && err?.[0]?.message === 'banned prompt') {
        errorMsg = t('spark_page.generate.error.banned_prompt');
      }

      setErrorMessage(errorMsg);
    }),
  };
}
