import clone from 'lodash.clone';
import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { SparkUserCreditsQuery, useSparkUserCreditsQuery } from 'generated/graphql';

export function useUpdateSparkCredits() {
  const queryClient = useQueryClient();

  return useCallback(
    function updateUsersSparkCredits(fn: (currentValue: number) => number) {
      queryClient.setQueryData<SparkUserCreditsQuery>(useSparkUserCreditsQuery.key({}), data => {
        if (!data) {
          return {
            getSparkUserCredits: {
              balance: 0,
              errors: null,
            },
          };
        }

        const nextData = clone(data);

        if (nextData?.getSparkUserCredits.balance) {
          nextData.getSparkUserCredits.balance = fn(nextData.getSparkUserCredits.balance);
        }

        return nextData;
      });
    },
    [queryClient],
  );
}
