import { atom, useAtom } from 'jotai';

import { Month, PromptsFilter } from 'generated/graphql';
import { TranslatorParameters } from 'utilities/i18n';

const monthFilter = atom<Month | null>(null);
const yearFilter = atom<number | null>(null);
const promptFilter = atom<string | null>(null);

const promptFilters = atom(get => {
  let promptsFilter: PromptsFilter | undefined = undefined;

  const month = get(monthFilter);
  const year = get(yearFilter);
  const prompt = get(promptFilter);

  if (prompt) {
    promptsFilter = {};
    promptsFilter.prompt = prompt;
  }

  if (month && year) {
    promptsFilter = promptsFilter ?? {};
    promptsFilter.date = { month, year };
  }

  return promptsFilter;
});

export function useMonthFilter() {
  return useAtom(monthFilter);
}

export function useYearFilter() {
  return useAtom(yearFilter);
}

export function usePromptFilter() {
  return useAtom(promptFilter);
}

export function usePromptFilters() {
  return useAtom(promptFilters);
}

type ErrorMessageValue = TranslatorParameters | string;

const promptErrorMessage = atom<null | ErrorMessageValue>(null);

export function usePromptErrorMessage() {
  return useAtom(promptErrorMessage);
}
