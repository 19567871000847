import { useAtom } from 'jotai';
import { useHydrateAtoms } from 'jotai/utils';
import { useRouter } from 'next/router';

import { MODEL_QUERY_KEY } from 'components/pages/sparkPage/context/CreateSparkPrompt';
import { useSparkPageContext } from 'components/pages/sparkPage/context/SparkPageContext';
import { SparkTools } from 'components/pages/sparkPage/context/SparkPageContext.types';
import { ModelTypeVersion } from 'generated/graphql';
import { atomWithLocalStorage } from 'lib/jotai-utils';
import { unArray } from 'utilities/js';

// TODO: replace with `Models` from graphql when the models are on graphql
const initialModelsPerTool: Partial<Record<SparkTools, ModelTypeVersion>> = {
  'cf-spark-crystalline': 'twod_v01',
};

// TODO: replace with `Models` from graphql when the models are on graphql
const validModels: Partial<Record<SparkTools, Set<ModelTypeVersion>>> = {
  'cf-spark-crystalline': new Set<ModelTypeVersion>([
    'twod_v01',
    'twod_v02',
    'threed_v01',
    'threed_v02',
    'ml6_v01',
    'ml6_v02',
  ]),
};

const modelAtom = atomWithLocalStorage<ModelTypeVersion | null>('modelTypeVersion', null);

export function useHydrateModel() {
  const initialValue = useInitialModelValue();

  useHydrateAtoms(new Map([[modelAtom, initialValue]]));
}

export function useModel() {
  return useAtom(modelAtom);
}

function useInitialModelValue() {
  const { tool } = useSparkPageContext();
  const router = useRouter();
  const queryValue = unArray(router.query[MODEL_QUERY_KEY]);

  if (queryValue && validModels[tool]?.has(queryValue as ModelTypeVersion)) {
    return queryValue as ModelTypeVersion;
  }

  if (initialModelsPerTool[tool]) {
    return initialModelsPerTool[tool] as ModelTypeVersion;
  }

  return null;
}
