import {
  CreateSparkCrystallinePromptMutation,
  CreateSparkPromptMutation,
  useCreateSparkCrystallinePromptMutation,
  useCreateSparkPromptMutation,
} from 'generated/graphql';

import { useCreateSparkPromptOptions } from '~/sparkPage/hooks/useCreateSparkPromptOptions';

export function useMakeCreateSparkPromptMutation() {
  const generateOptions = useCreateSparkPromptOptions<CreateSparkPromptMutation>({
    resolveErrorMessage: data => data?.createSparkPrompt?.errors?.[0]?.message,
  });

  return useCreateSparkPromptMutation(generateOptions);
}

export function useMakeCreateSparkCrystallinePrompt() {
  const generateOptions = useCreateSparkPromptOptions<CreateSparkCrystallinePromptMutation>({
    resolveErrorMessage: data => data?.createSparkCrystallinePrompt?.errors?.[0]?.message,
  });

  return useCreateSparkCrystallinePromptMutation(generateOptions);
}
