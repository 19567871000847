import { atom, useAtom } from 'jotai';
import { useHydrateAtoms } from 'jotai/utils';
import { useRouter } from 'next/router';
import { useRef } from 'react';

import { unArray } from 'utilities/js';

const initialPage = 1;

const currentPageAtom = atom<number>(initialPage);

export function useHydrateCurrentPage() {
  const initialValue = useInitialCurrentPage();

  useHydrateAtoms(new Map([[currentPageAtom, initialValue ?? initialPage]]));
}

export function useCurrentPage() {
  return useAtom(currentPageAtom);
}

function useInitialCurrentPage() {
  const firstCurrentPage = useRef<string | null>();
  const router = useRouter();

  if (!firstCurrentPage.current) {
    firstCurrentPage.current = unArray(router.query.page);
  }

  if (firstCurrentPage.current) {
    const value = Number(firstCurrentPage.current);

    if (value && !Number.isNaN(value)) {
      return value;
    }
  }

  return null;
}
