import { useAtom } from 'jotai';
import { useHydrateAtoms } from 'jotai/utils';
import { useRouter } from 'next/router';

import { TYPE_QUERY_KEY } from 'components/pages/sparkPage/context/CreateSparkPrompt';
import { atomWithLocalStorage } from 'lib/jotai-utils';
import { unArray } from 'utilities/js';

const typeAtom = atomWithLocalStorage<string | null>('spark_type', null);

export function useHydrateType() {
  const initialValue = useInitialTypeValue();

  useHydrateAtoms(new Map([[typeAtom, initialValue]]));
}

export function useType() {
  return useAtom(typeAtom);
}

function useInitialTypeValue() {
  const router = useRouter();
  const queryValue = unArray(router.query[TYPE_QUERY_KEY]);

  return queryValue;
}
