import { CreationType, SparkPromptsQuery } from 'generated/graphql';
import { SparkTools } from 'components/pages/sparkPage/context/SparkPageContext.types';
import {
  ImageVariant,
  Prompt,
  Image,
} from 'components/pages/sparkPage/components/sparkPageCreate/SparkPageCreate.types';

function imageIsInUpscalingState(p: Image) {
  return p.__typename === ImageVariant.UPSCALING;
}

function imageIsInGeneratingState(p: Image) {
  return p.__typename === ImageVariant.GENERATING;
}

export function calculateHasPromptImageInLoadingState(promptsToHandle: Array<Prompt>) {
  for (const prompt of promptsToHandle) {
    const promptImages = prompt?.images ?? [];

    for (const image of promptImages) {
      if (imageIsInGeneratingState(image) || imageIsInUpscalingState(image)) {
        // we can exit early so not to check all the other prompt images in the pages
        return true;
      }
    }
  }

  return false;
}

export function calculateHasPromptImageInGeneratingState(promptsToHandle: Array<Prompt>) {
  for (const prompt of promptsToHandle) {
    const promptImages = prompt?.images ?? [];

    for (const image of promptImages) {
      if (imageIsInGeneratingState(image)) {
        // we can exit early so not to check all the other prompt images in the pages
        return true;
      }
    }
  }

  return false;
}

export function sparkToolToCreationType(cfTool: SparkTools): CreationType {
  if (cfTool === 'cf-spark-patterns') {
    return 'patterns';
  }

  //TODO remove this casting after BE is implemented
  if (cfTool === 'cf-spark-tv') {
    return 'tv' as CreationType;
  }

  if (cfTool === 'cf-spark-imagemix') {
    return 'imagemix';
  }

  if (cfTool === 'cf-spark-crystalline') {
    return 'crystalline';
  }

  if (cfTool === 'cf-spark-sketch') {
    return 'sketch';
  }

  if (cfTool === 'cf-spark-coloring-page') {
    return 'coloringPage';
  }

  return 'art';
}

export function sparkCreationTypeToTool(creationType?: CreationType | null): SparkTools {
  if (creationType === 'patterns') {
    return 'cf-spark-patterns';
  }

  if (creationType === 'imagemix') {
    return 'cf-spark-imagemix';
  }

  if (creationType === 'crystalline') {
    return 'cf-spark-crystalline';
  }

  if (creationType === 'sketch') {
    return 'cf-spark-sketch';
  }

  if (creationType === 'coloringPage') {
    return 'cf-spark-coloring-page';
  }

  return 'cf-spark-art';
}

export const creationTypes: Array<CreationType> = ['art', 'crystalline', 'patterns', 'imagemix', 'coloringPage'];

export function hasPromptsFromPromptsQuery(query?: SparkPromptsQuery | null) {
  const prompts = query?.me?.user?.spark?.prompts.items;

  return Array.isArray(prompts) && prompts.length > 0;
}
