import { useCallback, useContext, useMemo } from 'react';
import { useQueryClient, UseQueryOptions } from '@tanstack/react-query';

import { useSparkPageContext } from 'components/pages/sparkPage/context/SparkPageContext';
import { queryKey } from 'components/pages/sparkPage/hooks/useInfinitePrompts';
import { useCurrentPage, usePromptFilters } from 'components/pages/sparkPage/state';
import { getImageAspectRatioFromImageRatios } from 'components/pages/sparkPage/utils/getImageAspectRatio';
import { sparkToolToCreationType } from 'components/pages/sparkPage/utils/prompts';
import {
  SparkPromptsQuery,
  useSparkPromptsQuery,
  useSparkQueueQuery,
  useSparkUserCreditsQuery,
} from 'generated/graphql';
import { useOptionalUser } from 'hooks/useAuthState/useAuthState';

import { useLastGeneratedPromptId } from '~/sparkPage/hooks/useLastGeneratedPromptId';
import { toPrompt } from '~/sparkPage/utils/utils';
import {
  CreateSparkPromptContext,
  SparkPageImageRatioGetterContext,
  SparkPageImageRatioSetterContext,
  SparkPageInputGetterContext,
  SparkPageInputSetterContext,
  SparkPageProductListContext,
  SparkPageSubmitContext,
  SparkPageUploadContext,
} from './CreateSparkPrompt';

function guardContext<T>(context: T, error: string): T {
  if (context === null) {
    throw new Error(error);
  }

  return context;
}

export function useCreateSparkPrompt() {
  return guardContext(
    useContext(CreateSparkPromptContext),
    '`useCreateSparkPrompt` must be used within a <CreateSparkPromptProvider />',
  );
}

export function useSparkPageCreateImageRatio() {
  return guardContext(
    useContext(SparkPageImageRatioGetterContext),
    '`useSparkPageCreateImageRatio` must be used within a <SparkPageCreateProvider />',
  );
}

export function useSparkPageCreateSetImageRatio() {
  return guardContext(
    useContext(SparkPageImageRatioSetterContext),
    '`useSparkPageCreateSetImageRatio` must be used within a <SparkPageCreateProvider />',
  );
}

export function useSparkPageCreateInput(component: string) {
  return guardContext(
    useContext(SparkPageInputGetterContext),
    `\`useSparkPageCreateInput\` in <${component} /> must be used within <SparkPageCreateInput />`,
  );
}

export function useSparkPageCreateSetInput() {
  return guardContext(
    useContext(SparkPageInputSetterContext),
    '`useSparkPageCreateSetInput` must be used within a <SparkPageCreateInput />',
  );
}

export function useSparkPageProductList() {
  return guardContext(
    useContext(SparkPageProductListContext),
    '`useSparkPageProductList` must be used within a <SparkPageProductListContext.Provider />',
  );
}

export function useSparkPageUpload() {
  return guardContext(
    useContext(SparkPageUploadContext),
    '`useSparkPageUpload` must be used within a <SparkPageUploadContext.Provider />',
  );
}

/**
 * Instead you use `useOnSendPromptSpark` which also validates the input for you.
 * The below hook does not validate the input
 */
export function useSparkPageSubmit() {
  return guardContext(
    useContext(SparkPageSubmitContext),
    '`useSparkPageSubmit` must be used within a <SparkPageSubmitContext.Provider />',
  );
}

export function usePromptsWithoutQueuedPrompt() {
  const { queuingPrompt } = useCreateSparkPrompt();
  const paginatedPrompts = useSparkPrompts({ notifyOnChangeProps: ['data'] });
  const lastGeneratedPromptId = useLastGeneratedPromptId();

  const promptsData = paginatedPrompts.data?.me?.user?.spark?.prompts?.items?.map(toPrompt) ?? [];

  if (queuingPrompt) {
    return promptsData && lastGeneratedPromptId
      ? promptsData.filter(prompt => prompt.id !== lastGeneratedPromptId)
      : [];
  }

  return promptsData ?? [];
}

type UseSparkPromptsOptions = {
  notifyOnChangeProps?: UseQueryOptions['notifyOnChangeProps'];
};

export function useSparkPrompts(options?: UseSparkPromptsOptions) {
  const [currentPage] = useCurrentPage();
  const user = useOptionalUser();
  const [promptFilters] = usePromptFilters();
  const creationType = useCreationType();

  return useSparkPromptsQuery(
    { page: currentPage, creationType, filter: promptFilters },
    {
      enabled: Boolean(user?.identification.id),
      keepPreviousData: true, // this will display the previous pages data until the new one is received
      notifyOnChangeProps: options?.notifyOnChangeProps,
    },
  );
}

/**
 * Deletes a function from local state, for optimistic UI
 */
export function useSoftDeleteSparkPrompt() {
  const prompts = useSparkPrompts({ notifyOnChangeProps: ['data'] });
  const queryClient = useQueryClient();

  return useCallback(
    function softDeletePrompt(promptId: string) {
      // manually emove a single value from the page
      const items = prompts.data?.me?.user?.spark?.prompts?.items?.filter(val => val.id !== promptId) ?? [];

      queryClient.setQueryData<SparkPromptsQuery>(queryKey, data => ({
        ...data,
        items,
      }));
    },
    [prompts.data, queryClient],
  );
}

export function useSparkQueue() {
  const user = useOptionalUser();
  const prompts = useSparkPrompts({ notifyOnChangeProps: ['data'] });

  return useSparkQueueQuery(
    {},
    {
      enabled: Boolean(user?.identification.id) && prompts?.data && prompts?.data.me?.user?.spark.balance === 0,
    },
  );
}

export function useCreationType() {
  const { tool } = useSparkPageContext();

  return useMemo(() => sparkToolToCreationType(tool), [tool]);
}

export function useImageAspectRatio() {
  const imageRatio = useSparkPageCreateImageRatio();

  return useMemo(() => getImageAspectRatioFromImageRatios(imageRatio), [imageRatio]);
}

type UseSparkUserCreditsOptions = {
  notifyOnChangeProps?: UseQueryOptions['notifyOnChangeProps'];
};

export function useSparkUserCredits(options?: UseSparkUserCreditsOptions) {
  const user = useOptionalUser();

  return useSparkUserCreditsQuery(
    {},
    { enabled: Boolean(user?.identification.id), notifyOnChangeProps: options?.notifyOnChangeProps },
  );
}

export function useSparkUserCreditsBalance() {
  const userCredits = useSparkUserCredits();

  return userCredits.data?.getSparkUserCredits.balance || 0;
}
