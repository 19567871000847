import { createContext, useMemo, useContext, useRef } from 'react';

import { SparkPageContextValue, SparkPageContextProps, SparkTools, QueryParamTools } from './SparkPageContext.types';

const SparkPageContext = createContext<SparkPageContextValue>(null!);

export function useSparkPageContext() {
  const context = useContext(SparkPageContext);

  if (context === null) {
    throw new Error('`useSparkPageContext` must be used within a <SparkPageContextProvider />');
  }

  return context;
}

const defaultColumns = {
  xs: 6,
  md: 3,
} as const;

export function SparkPageContextProvider({ tool, columns = defaultColumns, children }: SparkPageContextProps) {
  const sparkInputRef = useRef<HTMLInputElement | HTMLTextAreaElement>();

  return (
    <SparkPageContext.Provider
      value={useMemo(
        () => ({
          tool,
          columns,
          sparkInputRef,
        }),
        [tool, columns, sparkInputRef],
      )}
    >
      {children}
    </SparkPageContext.Provider>
  );
}

export function mapQueryParamToSparkTool(tool: string | string[] | undefined): SparkTools {
  switch (tool) {
    case 'art':
      return 'cf-spark-art';
    case 'patterns':
      return 'cf-spark-patterns';
    case 'imagemix':
      return 'cf-spark-imagemix';
    case 'crystalline':
      return 'cf-spark-crystalline';
    case 'sketch':
      return 'cf-spark-sketch';
    case 'coloringPage':
      return 'cf-spark-coloring-page';
    default:
      return 'cf-spark-art';
  }
}

export function mapSparkToolToQueryParam(tool: SparkTools): QueryParamTools {
  switch (tool) {
    case 'cf-spark-art':
      return 'art';
    case 'cf-spark-patterns':
      return 'patterns';
    case 'cf-spark-imagemix':
      return 'imagemix';
    case 'cf-spark-crystalline':
      return 'crystalline';
    case 'cf-spark-sketch':
      return 'sketch';
    case 'cf-spark-coloring-page':
      return 'coloringPage';
    default:
      return 'art';
  }
}
