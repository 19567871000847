import React from 'react';
import { Box } from '@mui/material';

import { TooltipProps } from './Tooltip.types';
import * as S from './Tooltip.styles';

export const Tooltip = ({ variant, boxClassName, children, ...props }: TooltipProps) => (
  <S.Tooltip arrow classes={{ tooltip: variant, arrow: variant }} {...props}>
    <Box className={boxClassName}>{children}</Box>
  </S.Tooltip>
);
