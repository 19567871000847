import { useCallback } from 'react';
import { atom, useAtom } from 'jotai';

import { LocalPrompt, LocalVideoPrompt } from 'components/pages/sparkPage/context/CreateSparkPrompt.types';

const localPromptsAtom = atom<Array<LocalPrompt | LocalVideoPrompt>>([]);

export function useLocalPrompts() {
  const [localPrompts, setLocalPrompts] = useAtom(localPromptsAtom);

  const removeItem = useCallback(
    function removeItem(id?: string) {
      if (!id) {
        return;
      }

      setLocalPrompts(currentLocalPrompts => {
        const nextPrompts = currentLocalPrompts.filter(localPrompt => localPrompt.id !== id);

        return nextPrompts;
      });
    },
    [setLocalPrompts],
  );

  const addItem = useCallback(
    function addItem(localPrompt: LocalPrompt) {
      setLocalPrompts(currentLocalPrompts => {
        const nextPrompts = [localPrompt, ...currentLocalPrompts];

        return nextPrompts;
      });
    },
    [setLocalPrompts],
  );

  return { removeItem, addItem, localPrompts };
}
