import { useRouter } from 'next/router';
import { useEffect } from 'react';

export function useSyncCurrentPageToQueryParams(currentPage: number) {
  const router = useRouter();

  useEffect(() => {
    const query = { ...router.query };
    const currentPageValue = String(currentPage);

    if (query.page === currentPageValue) {
      return;
    }

    if (!query.page && currentPage === 1) {
      return;
    }

    if (currentPage && currentPage !== 1) {
      query.page = currentPageValue;
    } else {
      delete query.page;
    }

    router.push(
      {
        pathname: router.pathname,
        query,
      },
      undefined,
      {
        shallow: true,
      },
    );
  }, [currentPage, router]);
}
