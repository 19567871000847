import { atom, useAtom } from 'jotai';
import { useHydrateAtoms } from 'jotai/utils';
import { useRouter } from 'next/router';

import { unArray } from 'utilities/js';

import { IMAGE_QUERY_KEY, PROMPT_TEXT_QUERY_KEY } from '~/sparkPage/context/CreateSparkPrompt';

const initialInput = '';

const inputAtom = atom(initialInput);

export function useHydrateInput() {
  const initialValue = useInitialInput();

  useHydrateAtoms(new Map([[inputAtom, initialValue ?? initialInput]]));
}

export function useInput() {
  return useAtom(inputAtom);
}

function useInitialInput() {
  const router = useRouter();
  const initialPrompt = unArray(router.query[PROMPT_TEXT_QUERY_KEY]);
  const initialImage = unArray(router.query[IMAGE_QUERY_KEY]);

  return initialPrompt || initialImage;
}
