import { createContext, useContext, useMemo } from 'react';

import {
  CreateSparkPromptMutationContextDataValue,
  CreateSparkPromptMutationContextValue,
} from './CreateSparkPromptMutation.types';
import { resolveLastPromptId } from './CreateSparkPromptMutation.utils';
import { useSparkPageContext } from './SparkPageContext';
import { SparkTools } from './SparkPageContext.types';
import {
  useMakeCreateSparkCrystallinePrompt,
  useMakeCreateSparkPromptMutation,
} from '~/sparkPage/hooks/useMakeCreateSparkPromptMutation';

const CreateSparkPromptMutationContext = createContext<CreateSparkPromptMutationContextValue>(null!);
const CreateSparkPromptMutationDataContext = createContext<CreateSparkPromptMutationContextDataValue>(null!);

export function CreateSparkPromptMutationProvider({ children }: { children: React.ReactNode }) {
  const { tool } = useSparkPageContext();

  if (tool === 'cf-spark-crystalline') {
    return <CreateSparkPromptCrystallineProvider>{children}</CreateSparkPromptCrystallineProvider>;
  }

  if (process.env.NODE_ENV === 'development' && !sparkToolsForCreateSparkPrompt.has(tool)) {
    // eslint-disable-next-line no-console
    console.error(
      `Current Spark Tool ${tool} is not supported by useActiveCreateSparkPromptMutation(), please configure it to ensure that you are handling this case correctly.`,
    );
  }

  return <CreateSparkPromptProvider>{children}</CreateSparkPromptProvider>;
}

function CreateSparkPromptProvider({ children }: { children: React.ReactNode }) {
  const createSparkPrompt = useMakeCreateSparkPromptMutation();

  return (
    <CreateSparkPromptMutationContext.Provider value={{ type: 'spark', result: createSparkPrompt }}>
      <CreateSparkPromptMutationDataContextProvider>
        {useMemo(() => children, [children])}
      </CreateSparkPromptMutationDataContextProvider>
    </CreateSparkPromptMutationContext.Provider>
  );
}

function CreateSparkPromptCrystallineProvider({ children }: { children: React.ReactNode }) {
  const createSparkCrystallinePrompt = useMakeCreateSparkCrystallinePrompt();

  return (
    <CreateSparkPromptMutationContext.Provider value={{ type: 'crystalline', result: createSparkCrystallinePrompt }}>
      <CreateSparkPromptMutationDataContextProvider>
        {useMemo(() => children, [children])}
      </CreateSparkPromptMutationDataContextProvider>
    </CreateSparkPromptMutationContext.Provider>
  );
}

function CreateSparkPromptMutationDataContextProvider({ children }: { children: React.ReactNode }) {
  const mutation = useCreateSparkPromptMutationContext();

  const isLoading = mutation.result.isLoading;
  const promptId = resolveLastPromptId(mutation);

  return (
    <CreateSparkPromptMutationDataContext.Provider
      value={useMemo(
        () => ({
          isLoading,
          promptId,
        }),
        [isLoading, promptId],
      )}
    >
      {useMemo(() => children, [children])}
    </CreateSparkPromptMutationDataContext.Provider>
  );
}

const sparkToolsForCreateSparkPrompt = new Set<SparkTools>([
  'cf-spark-art',
  'cf-spark-imagemix',
  'cf-spark-patterns',
  'cf-spark-sketch',
  'cf-spark-tv',
  'cf-spark-coloring-page',
]);

export function useCreateSparkPromptMutationContext() {
  return useContext(CreateSparkPromptMutationContext);
}

export function useCreateSparkPromptMutationDataContext() {
  return useContext(CreateSparkPromptMutationDataContext);
}
