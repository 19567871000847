import { UseInfiniteQueryOptions } from '@tanstack/react-query';

import { SparkPromptsQuery, useInfiniteSparkPromptsQuery } from 'generated/graphql';

export const queryKey = useInfiniteSparkPromptsQuery.key();

export function useInfinitePrompts(options: UseInfiniteQueryOptions<SparkPromptsQuery> = {}) {
  return useInfiniteSparkPromptsQuery(
    'page',
    {},
    {
      ...options,
      getNextPageParam: lastPage => {
        const currentPage = lastPage?.me?.user?.spark?.prompts?.pagination?.page || null;
        const totalPages = lastPage?.me?.user?.spark?.prompts?.pagination?.totalPages || null;

        //Fetch next page
        if (currentPage && totalPages && currentPage < totalPages) {
          return { page: currentPage + 1 };
        }

        return undefined;
      },
    },
  );
}
